/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, CareInstructions, Headline, CareItem, Obl} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Hyperpigmentation"), ", also known as dark spots, is a result of inflammation in the skin from prior injury or disease process such as acne or psoriasis."), React.createElement(_components.p, null, "Hyperpigmentation typically fades with time, but can be upsetting psychologically when present.")), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hyperpigmentation can be caused by ", React.createElement(_components.a, {
    href: "/acne-diet"
  }, "acne"), ".")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 400,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Hyperpigmentation can also be caused by ", React.createElement(_components.a, {
    href: "/sun-damage"
  }, "sun damage"), ".")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "Treatment"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Certain over the counter and prescription treatments can help."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, "Ingredients such as ", React.createElement(Obl, {
    to: "https://amzn.to/3GyO44j",
    title: "Amazon Affiliate Link"
  }, "hydroquinone"), ", ", React.createElement(Obl, {
    to: "https://amzn.to/3EqjSpj",
    title: "Amazon Affiliate Link"
  }, "retinoids"), ", and ", React.createElement(Obl, {
    to: "https://amzn.to/3AE4kNx",
    title: "Amazon Affiliate Link"
  }, "salicylic"), ", ", React.createElement(Obl, {
    to: "https://amzn.to/3GSFAFt",
    title: "Amazon Affiliate Link"
  }, "glycolic"), ", and ", React.createElement(Obl, {
    to: "https://amzn.to/3OqK33D",
    title: "Amazon Affiliate Link"
  }, "azeleic acids"), " can help fade dark marks."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(Obl, {
    to: "https://amzn.to/3Op4fDd",
    title: "Amazon Affiliate Link"
  }, "Protecting your skin from the sun"), " is also important, as sun exposure can worsen hyperpigmentation."), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
